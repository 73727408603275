'use client';

import Error from 'next/error';
import { useEffect } from 'react';

const GlobalError = ({ error }: { error: unknown }) => {
  useEffect(() => {
    // TODO: handle error with datadog
  }, [error]);

  return (
    <html>
      <body>
        <Error statusCode={500} />
      </body>
    </html>
  );
};

export default GlobalError;
